import React, {useState, useEffect} from "../_snowpack/pkg/react.js";
export const Anchor = ({href, children, visible}) => {
  const [anchorTarget, setAnchorTarget] = useState(null);
  useEffect(() => {
    setAnchorTarget(document.getElementById(href));
  }, [href]);
  return /* @__PURE__ */ React.createElement("a", {
    className: visible ? "is-active" : "",
    onClick: (e) => {
      e.preventDefault();
      anchorTarget.scrollIntoView({
        behavior: "smooth"
      });
    }
  }, children);
};
