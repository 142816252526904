import React from "../_snowpack/pkg/react.js";
import {useInView} from "../_snowpack/pkg/react-intersection-observer.js";
export const Section = ({scrollRef, id, title, children, bare = false}) => {
  return /* @__PURE__ */ React.createElement("div", {
    ref: scrollRef,
    style: bare ? null : {padding: "15px 60px"},
    id
  }, /* @__PURE__ */ React.createElement("h1", {
    className: "title"
  }, title), /* @__PURE__ */ React.createElement("div", {
    className: bare ? null : "content"
  }, children));
};
export const useSection = ({...props}) => {
  const {ref, inView} = useInView({});
  return [
    /* @__PURE__ */ React.createElement(Section, {
      scrollRef: ref,
      ...props
    }),
    inView
  ];
};
