import React from "../_snowpack/pkg/react.js";
import ReactDOM from "../_snowpack/pkg/react-dom.js";
import {
  Timeline,
  Container,
  YearContent,
  BodyContent,
  Section as TimeSection,
  Description
} from "../_snowpack/pkg/vertical-timeline-component-react.js";
import {identity, unzip, findIndex} from "../_snowpack/pkg/lodash/fp.js";
import {useSection} from "./Section.js";
import {Anchor} from "./Anchor.js";
const Intro = () => {
  return /* @__PURE__ */ React.createElement("div", null, "Hi, I'm Épiphanie. You can contact me at ", /* @__PURE__ */ React.createElement("a", {
    href: "gedeon@epiphanie.me"
  }, "gedeon@epiphanie.me"), " ", /* @__PURE__ */ React.createElement("br", null), "Here is my background and some stuff I made. ", /* @__PURE__ */ React.createElement("br", null));
};
const MyTimeline = () => {
  const customTheme = {
    yearColor: "#99b3c6",
    lineColor: "#363a3d",
    dotColor: "#1d1f20",
    borderDotColor: "#3e4346",
    titleColor: "#99b3c6",
    subtitleColor: "#c39f6f",
    textColor: "#d0cca4"
  };
  return /* @__PURE__ */ React.createElement(Timeline, {
    theme: customTheme,
    dateFormat: "ll"
  }, /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(YearContent, {
    startDate: "2018",
    endDate: "2020"
  }), /* @__PURE__ */ React.createElement(BodyContent, null, /* @__PURE__ */ React.createElement(TimeSection, {
    title: "Freelance in programming"
  }, /* @__PURE__ */ React.createElement(Description, {
    text: "Worked for web127degrees"
  }), /* @__PURE__ */ React.createElement(Description, {
    text: "Worked on freelancer.com"
  })))), /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(YearContent, {
    startDate: "2017",
    endDate: "2018"
  }), /* @__PURE__ */ React.createElement(BodyContent, null, /* @__PURE__ */ React.createElement(TimeSection, {
    title: "Self study for ENS entrance exam in Mathematics and Computer Science"
  }, /* @__PURE__ */ React.createElement(Description, {
    text: "Learning undergrad-level calculus and algebra, topology, logic and category theory"
  })))), /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(YearContent, {
    startDate: "2015",
    endDate: "2016"
  }), /* @__PURE__ */ React.createElement(BodyContent, null, /* @__PURE__ */ React.createElement(TimeSection, {
    title: "Voluntary service at the Vaclav Havel library"
  }, /* @__PURE__ */ React.createElement(Description, {
    variant: "subtitle",
    text: "France"
  }), /* @__PURE__ */ React.createElement(Description, {
    text: "Homework and school assistant for students"
  }), /* @__PURE__ */ React.createElement(Description, {
    text: "Media curator for science communication"
  })))), /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(YearContent, {
    startDate: "2014",
    endDate: "2015"
  }), /* @__PURE__ */ React.createElement(BodyContent, null, /* @__PURE__ */ React.createElement(TimeSection, {
    title: "Studies in Game Design and Development"
  }, /* @__PURE__ */ React.createElement(Description, {
    variant: "subtitle",
    text: "Digipen Bilbao (Real Time Interactive Simulation)"
  })), /* @__PURE__ */ React.createElement(TimeSection, {
    title: "Technical Director for a student project"
  }, /* @__PURE__ */ React.createElement(Description, {
    variant: "subtitle",
    text: "Digipen Bilbao (Real Time Interactive Simulation)"
  }), /* @__PURE__ */ React.createElement(Description, {
    text: "Wrote technical documents to guide the project's conception"
  }), /* @__PURE__ */ React.createElement(Description, {
    text: "Wrote the code skeleton of the project"
  }), /* @__PURE__ */ React.createElement(Description, {
    text: "Coded the game alongside other developpers"
  })))), /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(YearContent, {
    startDate: "2013"
  }), /* @__PURE__ */ React.createElement(BodyContent, null, /* @__PURE__ */ React.createElement(TimeSection, {
    title: "Baccalauréat in general science (speciality in mathematics)"
  }, /* @__PURE__ */ React.createElement(Description, {
    variant: "subtitle",
    text: "France"
  })))));
};
const Skills = () => {
  return /* @__PURE__ */ React.createElement("div", null, "Some things I learned by my own:", /* @__PURE__ */ React.createElement("ul", null, /* @__PURE__ */ React.createElement("li", null, "Python, Django, NumPy"), /* @__PURE__ */ React.createElement("li", null, "JavaScript, Svelte, React, Node, GraphQL"), /* @__PURE__ */ React.createElement("li", null, "Quantum computing and ZX calculus"), /* @__PURE__ */ React.createElement("li", null, "Coq, SSReflect, proof assistants and formal logic")));
};
const Projects = () => {
  return /* @__PURE__ */ React.createElement("dl", null, /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    className: "is-button is-link",
    href: "https://github.com/joy-void-joy/"
  }, "My github profile")), /* @__PURE__ */ React.createElement("dd", null, /* @__PURE__ */ React.createElement("br", null)), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    className: "is-button is-link",
    href: "https://freelancer.com/u/eleirin"
  }, "My freelancer.com profile")), /* @__PURE__ */ React.createElement("dd", null, /* @__PURE__ */ React.createElement("br", null)), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "https://github.com/joy-void-joy/coq-pseudoreals"
  }, "Coq Pseudoreals")), /* @__PURE__ */ React.createElement("dd", null, "An early attempt to formalize pseudoreals in Coq. For the moment, it mostly contains formalization of ensembles and correctly-assessed position on sequences."), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "https://github.com/joy-void-joy/tacocast"
  }, "Tacocast")), /* @__PURE__ */ React.createElement("dd", null, "An automatic text-to-speech podcast generator using ", /* @__PURE__ */ React.createElement("a", {
    href: "https://github.com/mozilla/TTS"
  }, "TTS"), ". Based on, and meant to be an extension of the ", /* @__PURE__ */ React.createElement("a", {
    href: "https://forum.effectivealtruism.org/posts/JTZTBienqWEAjGDRv/listen-to-more-ea-content-with-the-nonlinear-library"
  }, "Non linear Library")), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "https://github.com/joy-void-joy/bindfiles"
  }, "Bindfiles")), /* @__PURE__ */ React.createElement("dd", null, "A way to serve text files with a contenteditable to modify their content"), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "http://kinea.media/en/human-sciences/women-artists-men-inequalities"
  }, "Where are all the women artists?")), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "http://kinea.media/en/politics-society/prison-alternative-sentences"
  }, "Prisons and “alternatives sentences”")), /* @__PURE__ */ React.createElement("dd", null, "Two translations I made for ", /* @__PURE__ */ React.createElement("a", {
    href: "http://kinea.media"
  }, "kinea.media")));
};
const Jams = () => {
  return /* @__PURE__ */ React.createElement("div", null, "These were little experiments, either for the One Hour Game Jams, or as experiments typically made under an hour or two:", /* @__PURE__ */ React.createElement("dl", null, /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "./jams/force_wall"
  }, "Force wall")), /* @__PURE__ */ React.createElement("dd", null, "One Hour Game Jam's theme: Fourth wall", /* @__PURE__ */ React.createElement("br", null), "My first one hour game jam, a little project in phaser"), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "./jams/automaton"
  }, "Automaton")), /* @__PURE__ */ React.createElement("dd", null, "One Hour Game Jam's theme: Only 4 colors", /* @__PURE__ */ React.createElement("br", null), "A cellular automata made in Phaser"), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "./jams/outofcontrol"
  }, "Out of control")), /* @__PURE__ */ React.createElement("dd", null, "A prototype made on GMTK 2020's theme: Out of control, made with Defold"), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "fiction/stone_dagger.pdf"
  }, "The stone dagger")), /* @__PURE__ */ React.createElement("dd", null, "A story about compassion and how narratives twist our perspectives"), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "fictions/library"
  }, "The library")), /* @__PURE__ */ React.createElement("dd", null, "An analogy I wrote when I was still grasping modularism and how access speed difference would modify in our relationship to it"), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "./jams/boids"
  }, "Boids")), /* @__PURE__ */ React.createElement("dd", null, "A hello world made in Phaser"), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "./jams/fmod"
  }, "Defold FMOD")), /* @__PURE__ */ React.createElement("dd", null, "Testing the integration between FMOD and Defold"), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "./krita/fire.png"
  }, "Fire")), /* @__PURE__ */ React.createElement("dd", null, "A fire made with Krita - testing the wet bristle brush"), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "./krita/pillow.png"
  }, "Pillow")), /* @__PURE__ */ React.createElement("dd", null, "Studies of the ", /* @__PURE__ */ React.createElement("a", {
    href: "https://www.youtube.com/watch?v=_zDpQ4wFeko"
  }, "X-ray construction methods"), ". Trying to recreate my bedroom")));
};
const French = () => {
  return /* @__PURE__ */ React.createElement("div", null, "Some fictions I did not have the time to translate yet:", /* @__PURE__ */ React.createElement("dl", null, /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "fictions/lupgrade"
  }, "L'upgrade")), /* @__PURE__ */ React.createElement("dd", null, "A transhumanist story about two emulated minds separated by time"), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "fictions/feu"
  }, "Le feu")), /* @__PURE__ */ React.createElement("dd", null, "A story about an AI coming to grasp with a lack of terminal values."), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "poems/a_toi_mon_createur"
  }, "À toi mon créateur")), /* @__PURE__ */ React.createElement("dd", null, "A short poem about how consciousness works when it can be paused, stepped, or reversed"), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "fictions/changeyourpuppetmaster_com"
  }, "changeyourpuppetmaster.com")), /* @__PURE__ */ React.createElement("dd", null, "A mock-video's script about a puppet changing its own puppetmaster and what consent means in this case"), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "fictions/pillule.pdf"
  }, "Discussion au coins des rues: La pillule")), /* @__PURE__ */ React.createElement("dd", null, "A discussion on what it would mean if we were able to change our own terminal values and what would we choose"), /* @__PURE__ */ React.createElement("dt", null, /* @__PURE__ */ React.createElement("a", {
    href: "fictions/de_la_magie"
  }, "De la magie, et de son amoralité")), /* @__PURE__ */ React.createElement("dd", null, "An ethicist professor explains why we should not be practicing magic")));
};
const Main = () => {
  const sections = [
    {
      Component: Intro,
      title: "Épiphanie Gédéon",
      id: "intro"
    },
    {
      Component: Projects,
      title: "Projects and commissions",
      id: "projects"
    },
    {
      Component: Skills,
      title: "Skills",
      id: "skills"
    },
    {
      Component: MyTimeline,
      title: "Timeline",
      id: "timeline",
      bare: true
    },
    {
      Component: Jams,
      title: "Jams and toyings",
      id: "jams"
    },
    {
      Component: French,
      title: "Only in French for now",
      id: "french"
    }
  ];
  const [components, visibles] = unzip(sections.map(({Component, title, id, bare}) => useSection({key: id, title, id, bare, children: /* @__PURE__ */ React.createElement(Component, null)})));
  const visibleIndex = findIndex(identity)(visibles);
  return /* @__PURE__ */ React.createElement("div", {
    className: "columns"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "column is-1"
  }, /* @__PURE__ */ React.createElement("aside", {
    className: "menu"
  }, /* @__PURE__ */ React.createElement("ul", {
    className: "menu-list"
  }, sections.map(({id, title}, i) => /* @__PURE__ */ React.createElement("li", {
    key: id
  }, /* @__PURE__ */ React.createElement(Anchor, {
    href: id,
    visible: i === visibleIndex
  }, title)))))), /* @__PURE__ */ React.createElement("div", {
    className: "column"
  }, components));
};
ReactDOM.render(/* @__PURE__ */ React.createElement(Main, null), document.getElementById("root"));
